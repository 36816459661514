var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DeleteModal" }, [
    _c("h1", [_vm._v("¿Estás seguro que eliminar el registro?")]),
    _c(
      "div",
      [
        _c(
          "BaseButton",
          {
            staticClass: "button",
            on: {
              click: function($event) {
                return _vm.changeState(_vm.item)
              }
            }
          },
          [_vm._v(" Si ")]
        ),
        _c(
          "BaseButton",
          {
            staticClass: "button",
            attrs: { variation: "secondary" },
            on: {
              click: function($event) {
                return _vm.closeModal()
              }
            }
          },
          [_vm._v(" No ")]
        ),
        _c("div")
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }