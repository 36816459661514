var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "QuestionFile" },
    [
      _c("BaseInputFile", {
        attrs: { tag: "image", accept: _vm.accept },
        nativeOn: {
          change: function($event) {
            return _vm.loadImage($event)
          }
        }
      }),
      _c("div", {
        staticClass: "preview",
        style: { backgroundImage: "url(" + _vm.currentImage + ")" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }