var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addCenter" }, [
    _c(
      "form",
      {
        on: {
          input: function($event) {
            return _vm.validation($event)
          },
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.submit.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "form-body" },
          [
            _vm.entity === "centers"
              ? _c(
                  "FormComponent",
                  {
                    attrs: {
                      tag: "province",
                      orientation: "row",
                      errors: _vm.errors
                    }
                  },
                  [
                    _c(
                      "select",
                      {
                        attrs: {
                          id: "province",
                          name: "province",
                          required: ""
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChange($event)
                          }
                        }
                      },
                      _vm._l(3, function(item) {
                        return _c(
                          "option",
                          { key: item, domProps: { value: item } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("QUESTION.NA1.CHOICE." + item)) +
                                " "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              : _vm._e(),
            _vm.entity === "centers"
              ? _c(
                  "FormComponent",
                  {
                    staticClass: "code",
                    attrs: {
                      tag: "code",
                      orientation: "row",
                      errors: _vm.errors
                    }
                  },
                  [
                    _c("LoginInput", {
                      attrs: {
                        type: "text",
                        tag: "code",
                        placeholder: _vm.$t("CODE.CENTER"),
                        title: _vm.$t("FORM.ERROR.EMPTYFIELD")
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.fields, function(field, i) {
              return _c(
                "FormInput",
                _vm._b(
                  {
                    key: i,
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "error",
                          fn: function() {
                            return [
                              _c("ValidationError", {
                                attrs: { errors: _vm.errors, name: field.name }
                              })
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: field.value,
                      callback: function($$v) {
                        _vm.$set(field, "value", $$v)
                      },
                      expression: "field.value"
                    }
                  },
                  "FormInput",
                  field,
                  false
                )
              )
            })
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "form-footer" },
          [
            _c(
              "BaseButton",
              {
                staticClass: "button",
                attrs: { size: "s" },
                on: { click: _vm.submit }
              },
              [_vm._v(" " + _vm._s(_vm.$t("BUTTON.SAVE")) + " ")]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }