<template>
  <div class="Centers">
    <EmptySubHeader class="subheader" />

    <div
      v-if="!isLoading"
      class="content"
    >
      <TransitionFade>
        <div class="head">
          <h1>{{ $t('TITLE.STATS.CENTERS') }}</h1>

          <div class="Centers">
            <div>
              <BaseButton @click="openModal()">
                {{ $t('BUTTON.ADD') }}
              </BaseButton>
            </div>
          </div>

          <div />
        </div>
      </TransitionFade>

      <TransitionFade>
        <div class="table">
          <TableEntiy
            :items="centers"
            entity="Centers"
          />
        </div>
      </TransitionFade>

      <BaseModal
        v-show="isModalVisible"
        :header-visible="true"
        size="s"
        @close="close"
      >
        <AddEntity
          entity="centers"
          @add-entity="addCenter($event)"
        />
      </BaseModal>
    </div>
    <div v-else>
      <BaseSpinner />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import EmptySubHeader from '@/components/subheader/EmptySubHeader';
import TransitionFade from '@/transitions/TransitionFade';
import AddEntity from '@/components/common/AddEntity';

import TableEntiy from '@/components/common/TableEntity';

export default {
  components: {
    EmptySubHeader,
    TransitionFade,
    TableEntiy,
    AddEntity,
  },

  data() {
    return {
      isModalVisible: false,
    };
  },

  computed: {
    ...get('authModule', ['getUser']),
    ...get('adminModule', ['isLoading', 'getCenters']),


    user() {
      return this.getUser();
    },

    centers() {
      return this.getCenters();
    },
  },

  async created() {
    await this.$store.dispatch('adminModule/fetchCenters');
  },

  methods: {
    openModal() {
      this.isModalVisible = true;
    },

    close() {
      this.isModalVisible = false;
    },

    async addCenter(payload) {
      await this.$store.dispatch('adminModule/addCenter', payload);
      this.isModalVisible = false;
      window.location.reload();
    },

  },
};
</script>

<style lang="scss" scoped>
.Centers {
  .content {
    max-width: 1200px;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: auto;

    .head {
      background: #fff;
      padding: $spacing-s;
      width: 100%;
      box-shadow: $shadow-elevation-1;
      h1 {
        text-align: center;
        font-size: $font-size-2xl;
        font-weight: 600;
      }

      .Centers {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }

    .table {
      background: #fff;
      width: 100%;
      margin-top: $spacing-l;
      /* padding: $spacing-m;
       box-shadow: $shadow-elevation-1;*/
    }
    @media #{$breakpoint-lg-max} {
      margin: 0;
      padding: 0;
    }
  }

  @media #{$breakpoint-lg-max} {
    .subheader {
      display: none;
    }
    margin-top: 5rem;
  }
}
</style>
