<template>
  <div class="PaneAdmin">
    <EmptySubHeader class="subheader" />

    <div
      v-if="!isLoading"
      class="content"
    >
      <TransitionFade>
        <div class="head">
          <h1 v-if="user">
            {{ $t('TITLE.STATS.USERS') }}
          </h1>

          <div class="filter">
            <div>
              <p>{{ $t('TABLE.TITLE.TYPE') }}</p>
              <select v-model="type">
                <option value="">
                  {{ $t('SELECT.PLACEHOLDER.COURSE') }}
                </option>
                <option
                  v-for="item in 4"
                  :key="item"
                  :value="item"
                >
                  {{ $t(`QUESTION.REGISTER.CHOICE.${item}`) }}
                </option>
              </select>
            </div>

            <TransitionFade v-if="type === 1">
              <div>
                <p>{{ $t('TITLE.STATS.CENTERS') }} </p>
                <select v-model="centerFilter">
                  <option
                    selected
                    value=""
                  >
                    {{ $t('SELECT.PLACEHOLDER.COURSE') }}
                  </option>
                  <option
                    v-for="center in centers"
                    :key="center.id"
                    :value="center.id"
                  >
                    {{ center.name }}
                  </option>
                </select>
              </div>
            </TransitionFade>

            <TransitionFade v-if="type === 1">
              <div>
                <p>{{ $t('FILTER.NAME.COURSE') }}</p>
                <select v-model="courseFilter">
                  <option
                    selected
                    value="null"
                  >
                    {{ $t('SELECT.PLACEHOLDER.COURSE') }}
                  </option>
                  <option
                    v-for="course in courses"
                    :key="course.id"
                    :value="course.id"
                  >
                    {{ course.name }}
                  </option>
                </select>
              </div>
            </TransitionFade>
            <TransitionFade v-if="componentSelected === 'AllUserProgram'">
              <div class="filter-program">
                <p>{{ $t('FILTER.PROGRAMS') }}</p>
                <BaseSelect
                  v-model="searchProgram"
                  v-bind="questionAttributes"
                />
                <p>Total {{ tableUserProgram.length }}</p>
              </div>
            </TransitionFade>
          </div>
          <div />
        </div>
      </TransitionFade>

      <BaseButton
        class="button-report"
        @click="downloadReport()"
      >
        Descargar informe
      </BaseButton>

      <div class="menu-component">
        <!-- <BaseButton>Programas realizados</BaseButton>
        <BaseButton>Mostrar todos</BaseButton> -->
        <a
          id="user-program"
          class="active"
          @click="changeComponent('AllUserProgram')"
        >Historial programas</a>
        <a
          id="all-user"
          @click="changeComponent('TableAllUser')"
        >Todos los usuari@s</a>
      </div>
      <div class="components">
        <keep-alive>
          <component
            :is="componentSelected"
            :items="tableUser"
            :user-programs="tableUserProgram"
          />
        </keep-alive>
      </div>

      <!--     <div class="users">
        <TableAllUser :items="tableUser" />
      </div> -->
    </div>
    <div v-else>
      <BaseSpinner />
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import EmptySubHeader from '@/components/subheader/EmptySubHeader';
import TransitionFade from '@/transitions/TransitionFade';
import TableAllUser from '@/components/panel-admin/AllUser';
import AllUserProgram from '@/components/panel-admin/AllUserPrograms';


export default {
  components: {
    EmptySubHeader,
    TransitionFade,
    TableAllUser,
    AllUserProgram,
  },

  data() {
    return {
      itemsSearch:
      {
        question: 'REGISTER',
        choices: [1, 2, 3, 4],
      },
      type: '',
      centerFilter: '',
      courseFilter: '',
      componentSelected: 'AllUserProgram',
      searchProgram: '',
      itemsSearchProgram:
      {
        question: 'PROGRAM',
        choices: [1, 2, 3, 4, 5],
      },
    };
  },


  computed: {
    ...get('authModule', ['getUser']),
    ...get('adminModule', [
      'isLoading',
      'getAllusers',
      'getCenters',
      'getCourses',
      'getFilterRegister',
      'getFilterUserByCenter',
      'getFilterUserByCourse',
      'getFilterUserCenterAndCourse',
      'getCenterwhithstudent',
      'getCoursewhithstudent',
      'getUsersPrograms',
    ]),

    user() {
      return this.getUser();
    },

    allUsers() {
      return this.getAllusers();
    },

    userPrograms() {
      return this.getUsersPrograms();
    },

    filterRegisterUser() {
      return this.getFilterRegister(this.type);
    },

    filterUserByCenter() {
      const idCenter = parseInt(this.centerFilter, 10);
      return this.getFilterUserByCenter(idCenter);
    },

    filterUserByCourse() {
      return this.getFilterUserByCourse(this.courseFilter);
    },

    filterUserCenterAndCourse() {
      const data = {
        center: parseInt(this.centerFilter, 10),
        course: this.courseFilter,
      };
      return this.getFilterUserCenterAndCourse(data);
    },

    tableUser() {
      if (this.type && this.centerFilter !== '' && this.courseFilter !== '') {
        return this.filterUserCenterAndCourse;
      }
      if (this.type && this.centerFilter !== '' && this.courseFilter === '') {
        return this.filterUserByCenter;
      }
      if (this.type && this.courseFilter !== '' && this.centerFilter === '') {
        return this.filterUserByCourse;
      }
      if (this.type && this.centerFilter === '' && this.courseFilter === '') {
        return this.filterRegisterUser;
      }
      return this.allUsers;
    },


    tableUserProgram() {
      if (this.type && this.centerFilter !== '' && this.courseFilter !== '' && this.searchProgram === '') {
        const params = {
          center: parseInt(this.centerFilter, 10),
          course: this.courseFilter,
        };
        return this.userPrograms?.filter(user => user.center === params.center && user.course === params.course);
      }

      if (this.type && this.centerFilter === '' && this.courseFilter === '' && this.searchProgram !== '') {
        return this.userPrograms?.filter(user => user.typeRegister === parseInt(this.type, 10) && user.programId === parseInt(this.searchProgram, 10));
      }

      if (this.type && this.centerFilter !== '' && this.courseFilter === '' && this.searchProgram !== '') {
        const params = {
          center: parseInt(this.centerFilter, 10),
          course: this.courseFilter,
        };

        return this.userPrograms?.filter(user => user.typeRegister === parseInt(this.type, 10) && user.programId === parseInt(this.searchProgram, 10) && user.center === params.center);
      }

      if (this.type && this.centerFilter === '' && this.courseFilter !== '' && this.searchProgram !== '') {
        const params = {
          center: parseInt(this.centerFilter, 10),
          course: this.courseFilter,
        };

        return this.userPrograms?.filter(user => user.typeRegister === parseInt(this.type, 10) && user.programId === parseInt(this.searchProgram, 10) && user.course === params.course);
      }

      if (this.type && this.centerFilter !== '' && this.courseFilter === '') {
        return this.userPrograms?.filter(user => user.center === this.centerFilter);
      }
      if (this.type && this.courseFilter !== '' && this.centerFilter === '') {
        return this.userPrograms?.filter(user => user.course === this.courseFilter);
      }
      if (this.type && this.centerFilter === '' && this.courseFilter === '') {
        return this.userPrograms?.filter(user => user.typeRegister === this.type);
      }

      if (this.type && this.centerFilter !== '' && this.courseFilter !== '' && this.searchProgram !== '') {
        const params = {
          center: parseInt(this.centerFilter, 10),
          course: this.courseFilter,
        };
        return this.userPrograms?.filter(user => user.center === params.center && user.course === params.course && user.programId === parseInt(this.searchProgram, 10));
      }

      if (this.searchProgram !== '' && this.type === '') {
        return this.userPrograms?.filter(user => user.programId === parseInt(this.searchProgram, 10));
      }

      if (this.searchProgram !== '' && this.type) {
        return this.userPrograms?.filter(user => user.programId === parseInt(this.searchProgram, 10) && user.typeRegister === parseInt(this.type, 10));
      }
      return this.userPrograms;
    },

    centers() {
      return this.getCenterwhithstudent();
    },

    courses() {
      return this.getCoursewhithstudent();
    },

    questionAttributes() {
      return Object.assign({}, this.itemsSearchProgram, { name: 'PROGRAM', disabled: this.isDisabled });
    },
  },

  async created() {
    await this.$store.dispatch('adminModule/fetchAllUsers');
    await this.$store.dispatch('adminModule/fetchCenters');
    await this.$store.dispatch('adminModule/fetchCourses');
    await this.$store.dispatch('adminModule/fetchCentersWhitStudent');
    await this.$store.dispatch('adminModule/fetchCoursesWhitStudent');
  },

  methods: {
    goToCourse() {
      return this.$router.push({ name: 'Courses' });
    },

    goToCenter() {
      return this.$router.push({ name: 'Centers' });
    },

    changeComponent(component) {
      this.componentSelected = component;

      console.log('Componente seleccionado', this.componentSelected);

      if (this.componentSelected === 'TableAllUser') {
        document.getElementById('all-user').classList.add('active');
        document.getElementById('user-program').classList.remove('active');
      } else if (this.componentSelected === 'AllUserProgram') {
        document.getElementById('all-user').classList.remove('active');
        document.getElementById('user-program').classList.add('active');
      }
    },

    async downloadReport() {
      const locales = [
        {
          id: 1,
          locale: 'eu',
        },
        {
          id: 2,
          locale: 'es',
        },
        {
          id: 3,
          locale: 'en',
        },
      ];

      const { locale } = this.$i18n;
      const idLocale = locales.find(item => item.locale === locale).id;


      const excel = await this.$store.dispatch('adminModule/downloadReport', idLocale);

      const link = document.createElement('a');
      link.href = excel;
      link.download = 'report.xls';
      link.click();
    },
  },
};
</script>

<style lang="scss" scoped>
.PaneAdmin {
  .content {
    max-width: 1200px;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: auto;

    .head {
      background: #fff;
      padding: $spacing-l;
      width: 100%;
      box-shadow: $shadow-elevation-1;
      h1 {
        text-align: center;
        font-size: $font-size-3xl;
        font-weight: 600;
        text-transform: uppercase;
        color: $color-primary-dark;
        border-bottom: solid 2px;
      }
      .filter {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin-top: $spacing-xl;
        > div {
          background: #fff;
          box-shadow: $shadow-elevation-2;
          padding: $spacing-s;

          p {
            font-weight: 500;
            color: $color-primary-dark;
            margin: $spacing-xs;
          }
        }

        select {
          border: none;
          border-bottom: solid 2px $color-primary-dark;
          height: 2rem;
          width: 100%;
        }

        .filter-program {
          display: flex;
          flex-direction: column;
        }

        @media #{$breakpoint-lg-max} {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }

    .users {
      margin-top: $spacing-l;
      width: 100%;
    }

    .menu-component {
      display: flex;
      flex-direction: row;
      gap: 20px;
      margin-top: 1rem;
      margin-bottom: 1rem;
      justify-content: flex-start;
      /*       background: red; */
      width: 100%;
      a {
        color: $color-primary;
        font-weight: 600;
        cursor: pointer;
      }

      .active {
        color: $color-primary-dark;
        font-weight: 600;
        border-bottom: 1px solid;
      }
    }

    .components {
      width: 100%;
    }

    @media #{$breakpoint-lg-max} {
      margin: 0;
      padding: 0;
    }
  }

  @media #{$breakpoint-lg-max} {
    .subheader {
      display: none;
    }

    margin-top: 5rem;
  }

  .button-report {
    margin-left: auto;
    margin-top: 1rem;
  }
}
</style>
