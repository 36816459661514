<template>
  <div class="DeleteModal">
    <h1>¿Estás seguro que eliminar el registro?</h1>

    <div>
      <BaseButton
        class="button"
        @click="changeState(item)"
      >
        Si
      </BaseButton>

      <BaseButton
        class="button"
        variation="secondary"
        @click="closeModal()"
      >
        No
      </BaseButton>
      <div />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: undefined,
    },

    entity: {
      type: String,
      default: undefined,
    },
  },

  methods: {
    closeModal() {
      this.$emit('close-modal-delete', true);
    },

    changeState(item) {
      const data = {
        id: item.id,
        name: item.name,
        description: item.description,
        code: item.code,
        province: item.province,
        state: 0,
        entity: this.entity,
      };

      this.$emit('change-state', data);
    },
  },

};
</script>

<style lang="scss" scoped>
.DeleteModal{
    background: #fff;
    padding: $spacing-m;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h1{
      font-size: $font-size-xl;
      font-weight: 500;
      padding: $spacing-m;
    }

    div{
      display: flex;
      justify-content: center;
    }

      .button{
        margin: 0.5rem;
      }
  }

</style>
