<template>
  <div class="PaneAdmin">
    <EmptySubHeader class="subheader" />

    <div class="content">
      <TransitionFade>
        <div class="head">
          <h1 v-if="user">
            {{ $t('TITLE.STATS.ADMIN') }}
          </h1>

          <div />
        </div>
      </TransitionFade>

      <TransitionFade>
        <div class="users">
          <div
            v-for="(stat, index) in stats"
            :key="index"
            class="card"
            :style="{'border': `solid  1px ${stat.color}`}"
          >
            <p
              class="title"
              :style="{'border-bottom': `solid  1px ${stat.color}`}"
            >
              {{ $t(stat.title) }}
            </p>
            <div class="content-card">
              <p
                class="icono"
                :style="{'background': stat.color}"
              >
                <font-awesome-icon
                  size="lg"
                  :icon="stat.icon"
                />
              </p>
              <p class="value">
                {{ stat.total }}
              </p>
            </div>
          </div>
        </div>
      </TransitionFade>
    </div>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import EmptySubHeader from '@/components/subheader/EmptySubHeader';
import TransitionFade from '@/transitions/TransitionFade';

export default {
  components: {
    EmptySubHeader,
    TransitionFade,

  },

  computed: {
    ...get('authModule', ['getUser']),
    ...get('adminModule', ['getAllusers', 'getStats']),

    user() {
      return this.getUser();
    },

    allUsers() {
      return this.getAllusers();
    },

    stats() {
      return this.getStats();
    },
  },

  async created() {
    await this.$store.dispatch('adminModule/fetchAllUsers');
    await this.$store.dispatch('adminModule/fetchStats');
  },

  methods: {
    goToCourse() {
      return this.$router.push({ name: 'Courses' });
    },

    goToCenter() {
      return this.$router.push({ name: 'Centers' });
    },

    goToUsers() {
      return this.$router.push({ name: 'UserAdmin' });
    },
  },
};
</script>

<style lang="scss" scoped>
.PaneAdmin {
  .content {
    max-width: 1200px;
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: auto;

    .head {
      background: #fff;
      padding: $spacing-l;
      width: 100%;
      box-shadow: $shadow-elevation-1;
      h1 {
        text-align: center;
        font-size: $font-size-4xl;
        font-weight: 600;
        color: $color-primary-dark;
      }
      .links {
        display: flex;
        flex-wrap: wrap;
        margin-top: $spacing-l;
        gap: 20px;
        font-size: $font-size-xl;
        .item {
          flex: 1;
          background: $color-neutral-light;
          padding: $spacing-m;
          box-shadow: $shadow-elevation-1;
          text-align: center;
          color: $color-primary;
          transition: all;

          &:hover {
            cursor: pointer;
            background: $color-primary;
            color: #fff;
          }
        }
      }
      @media #{$breakpoint-lg-max} {
        margin-top: 5rem;
      }
    }

    .users {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background: #fff;
      justify-content: center;
      padding: $spacing-xl;
      margin-top: $spacing-l;
      width: 100%;
      gap: 20px;
      box-shadow: $shadow-elevation-2;

      .card {
        width: 320px;
        // border: solid 1px $color-neutral-dark;
        box-shadow: $shadow-elevation-1;
        border-radius: 11px;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        .title {
          //color: #fff;
          // background: $color-primary-dark;
          color: $color-primary;
          font-weight: bold;

          font-size: $font-size-xl;
          text-transform: uppercase;
          padding: $spacing-xs;
          text-align: center;
        }
        .content-card {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          .icono {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: $font-size-5xl;
            color: #fff;
            background: $color-primary;
            height: 8rem;
            border-radius: 0 0 0 10px;
            font-weight: 600;
          }
          .value {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fff;
            font-weight: 500;
            height: 8rem;
            color: $color-primary;
            font-size: 4rem;
            border-radius: 0 0 10px 0;
          }
        }
      }
    }

    @media #{$breakpoint-lg-max} {
      margin: 0;
      padding: 0;
    }
  }

  @media #{$breakpoint-lg-max} {
    .subheader {
      display: none;
    }
  }
}
</style>
