var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Centers" },
    [
      _c("EmptySubHeader", { staticClass: "subheader" }),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _c("TransitionFade", [
                _c("div", { staticClass: "head" }, [
                  _c("h1", [_vm._v(_vm._s(_vm.$t("TITLE.STATS.CENTERS")))]),
                  _c("div", { staticClass: "Centers" }, [
                    _c(
                      "div",
                      [
                        _c(
                          "BaseButton",
                          {
                            on: {
                              click: function($event) {
                                return _vm.openModal()
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("BUTTON.ADD")) + " ")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div")
                ])
              ]),
              _c("TransitionFade", [
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c("TableEntiy", {
                      attrs: { items: _vm.centers, entity: "Centers" }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "BaseModal",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isModalVisible,
                      expression: "isModalVisible"
                    }
                  ],
                  attrs: { "header-visible": true, size: "s" },
                  on: { close: _vm.close }
                },
                [
                  _c("AddEntity", {
                    attrs: { entity: "centers" },
                    on: {
                      "add-entity": function($event) {
                        return _vm.addCenter($event)
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _c("div", [_c("BaseSpinner")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }