var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PaneAdmin" },
    [
      _c("EmptySubHeader", { staticClass: "subheader" }),
      !_vm.isLoading
        ? _c(
            "div",
            { staticClass: "content" },
            [
              _c("TransitionFade", [
                _c("div", { staticClass: "head" }, [
                  _vm.user
                    ? _c("h1", [
                        _vm._v(" " + _vm._s(_vm.$t("TITLE.STATS.USERS")) + " ")
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "filter" },
                    [
                      _c("div", [
                        _c("p", [_vm._v(_vm._s(_vm.$t("TABLE.TITLE.TYPE")))]),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.type,
                                expression: "type"
                              }
                            ],
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.type = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              }
                            }
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("SELECT.PLACEHOLDER.COURSE")) +
                                  " "
                              )
                            ]),
                            _vm._l(4, function(item) {
                              return _c(
                                "option",
                                { key: item, domProps: { value: item } },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "QUESTION.REGISTER.CHOICE." + item
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]),
                      _vm.type === 1
                        ? _c("TransitionFade", [
                            _c("div", [
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.$t("TITLE.STATS.CENTERS")) + " "
                                )
                              ]),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.centerFilter,
                                      expression: "centerFilter"
                                    }
                                  ],
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.centerFilter = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { selected: "", value: "" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("SELECT.PLACEHOLDER.COURSE")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._l(_vm.centers, function(center) {
                                    return _c(
                                      "option",
                                      {
                                        key: center.id,
                                        domProps: { value: center.id }
                                      },
                                      [_vm._v(" " + _vm._s(center.name) + " ")]
                                    )
                                  })
                                ],
                                2
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.type === 1
                        ? _c("TransitionFade", [
                            _c("div", [
                              _c("p", [
                                _vm._v(_vm._s(_vm.$t("FILTER.NAME.COURSE")))
                              ]),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.courseFilter,
                                      expression: "courseFilter"
                                    }
                                  ],
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.courseFilter = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    { attrs: { selected: "", value: "null" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("SELECT.PLACEHOLDER.COURSE")
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._l(_vm.courses, function(course) {
                                    return _c(
                                      "option",
                                      {
                                        key: course.id,
                                        domProps: { value: course.id }
                                      },
                                      [_vm._v(" " + _vm._s(course.name) + " ")]
                                    )
                                  })
                                ],
                                2
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.componentSelected === "AllUserProgram"
                        ? _c("TransitionFade", [
                            _c(
                              "div",
                              { staticClass: "filter-program" },
                              [
                                _c("p", [
                                  _vm._v(_vm._s(_vm.$t("FILTER.PROGRAMS")))
                                ]),
                                _c(
                                  "BaseSelect",
                                  _vm._b(
                                    {
                                      model: {
                                        value: _vm.searchProgram,
                                        callback: function($$v) {
                                          _vm.searchProgram = $$v
                                        },
                                        expression: "searchProgram"
                                      }
                                    },
                                    "BaseSelect",
                                    _vm.questionAttributes,
                                    false
                                  )
                                ),
                                _c("p", [
                                  _vm._v(
                                    "Total " +
                                      _vm._s(_vm.tableUserProgram.length)
                                  )
                                ])
                              ],
                              1
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("div")
                ])
              ]),
              _c(
                "BaseButton",
                {
                  staticClass: "button-report",
                  on: {
                    click: function($event) {
                      return _vm.downloadReport()
                    }
                  }
                },
                [_vm._v(" Descargar informe ")]
              ),
              _c("div", { staticClass: "menu-component" }, [
                _c(
                  "a",
                  {
                    staticClass: "active",
                    attrs: { id: "user-program" },
                    on: {
                      click: function($event) {
                        return _vm.changeComponent("AllUserProgram")
                      }
                    }
                  },
                  [_vm._v("Historial programas")]
                ),
                _c(
                  "a",
                  {
                    attrs: { id: "all-user" },
                    on: {
                      click: function($event) {
                        return _vm.changeComponent("TableAllUser")
                      }
                    }
                  },
                  [_vm._v("Todos los usuari@s")]
                )
              ]),
              _c(
                "div",
                { staticClass: "components" },
                [
                  _c(
                    "keep-alive",
                    [
                      _c(_vm.componentSelected, {
                        tag: "component",
                        attrs: {
                          items: _vm.tableUser,
                          "user-programs": _vm.tableUserProgram
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c("div", [_c("BaseSpinner")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }