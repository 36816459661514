var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Edititem" }, [
    _c(
      "form",
      {
        on: {
          input: function($event) {
            return _vm.validation($event)
          },
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.submit.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "form-body" },
          [
            _vm.entity === "Centers"
              ? _c(
                  "FormComponent",
                  {
                    attrs: {
                      tag: "province",
                      orientation: "row",
                      errors: _vm.errors
                    }
                  },
                  [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.itemCopy.province,
                            expression: "itemCopy.province"
                          }
                        ],
                        attrs: {
                          id: "province",
                          name: "province",
                          required: ""
                        },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.itemCopy,
                              "province",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      _vm._l(3, function(prov) {
                        return _c(
                          "option",
                          { key: prov, domProps: { value: prov } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("QUESTION.NA1.CHOICE." + prov)) +
                                " "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              : _vm._e(),
            _vm.entity === "Centers"
              ? _c(
                  "FormComponent",
                  {
                    staticClass: "code",
                    attrs: {
                      tag: "code",
                      orientation: "row",
                      errors: _vm.errors
                    }
                  },
                  [
                    _c("LoginInput", {
                      attrs: {
                        type: "text",
                        tag: "code",
                        placeholder: _vm.$t("CODE.CENTER"),
                        title: _vm.$t("FORM.ERROR.EMPTYFIELD")
                      },
                      model: {
                        value: _vm.itemCopy.code,
                        callback: function($$v) {
                          _vm.$set(_vm.itemCopy, "code", $$v)
                        },
                        expression: "itemCopy.code"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "FormComponent",
              {
                staticClass: "form-component",
                attrs: { tag: "name", orientation: "row", errors: _vm.errors }
              },
              [
                _c("LoginInput", {
                  attrs: {
                    type: "text",
                    tag: "name",
                    placeholder: _vm.$t("PROFILE.NAME.LABEL"),
                    title: _vm.$t("FORM.ERROR.EMPTYFIELD"),
                    required: true
                  },
                  model: {
                    value: _vm.itemCopy.nameEdit,
                    callback: function($$v) {
                      _vm.$set(_vm.itemCopy, "nameEdit", $$v)
                    },
                    expression: "itemCopy.nameEdit"
                  }
                })
              ],
              1
            ),
            _c(
              "FormComponent",
              {
                staticClass: "form-component",
                attrs: {
                  tag: "description",
                  orientation: "row",
                  errors: _vm.errors
                }
              },
              [
                _c("LoginInput", {
                  attrs: {
                    type: "text",
                    tag: "description",
                    placeholder: _vm.$t("PROFILE.NAME.LABEL"),
                    title: _vm.$t("FORM.ERROR.EMPTYFIELD")
                  },
                  model: {
                    value: _vm.itemCopy.description,
                    callback: function($$v) {
                      _vm.$set(_vm.itemCopy, "description", $$v)
                    },
                    expression: "itemCopy.description"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-footer" },
          [
            _c(
              "BaseButton",
              {
                staticClass: "button",
                attrs: { size: "s" },
                on: { click: _vm.submit }
              },
              [_vm._v(" " + _vm._s(_vm.$t("BUTTON.SAVE")) + " ")]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }