<template>
  <div class="TabledataItem">
    <div class="table">
      <TransitionFade>
        <BaseTable
          v-if="items"
          :headers="headers"
          :body="tableReports"
          :pagination="{current: pageActual, size: 10}"
          @set-page="setPage"
        >
          <template
            v-for="(item, i) in itemsByPage"
            v-slot:[`actions-${i}`]
          >
            <div
              :key="i"
              class="table-actions"
            >
              <BaseButton
                variation="tertiary"
                size="s"
                @click="deleteItem(item)"
              >
                <font-awesome-icon
                  class="icon trash"
                  size="xs"
                  :icon="['fas', 'trash']"
                />
              </BaseButton>

              <BaseButton
                variation="secondary"
                size="s"
                @click="editEntity(item)"
              >
                <font-awesome-icon
                  class="icon"
                  size="xs"
                  :icon="['fas', 'edit']"
                />
              </BaseButton>
            </div>
          </template>
        </BaseTable>
      </TransitionFade>
    </div>

    <div v-if="dataItem!== undefined">
      <BaseModal
        v-show="isModalVisible"
        :header-visible="true"
        size="s"
        @close="close"
      >
        <EditEntity
          :item="dataItem"
          :entity="entity"
          @update-item="updateItem($event)"
        />
      </BaseModal>
    </div>

    <div v-if="dataItem!== undefined">
      <BaseModal
        v-show="isModalVisibleDelete"
        :header-visible="true"
        size="s"
        @close="closeModalDelete"
      >
        <DeleteItem
          :item="dataItem"
          :entity="entity"
          @close-modal-delete="closeModal($event)"
          @change-state="changeStateItem($event)"
        />
      </BaseModal>
    </div>
  </div>
</template>

<script>
import TransitionFade from '@/transitions/TransitionFade';
import EditEntity from '@/components/common/EditEntity';
import DeleteItem from '@/components/common/ModalDelete';


export default {
  components: {
    TransitionFade,
    EditEntity,
    DeleteItem,
  },

  props: {
    items: {
      type: Array,
      default: undefined,
    },

    entity: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      dataItem: undefined,
      isModalVisible: false,
      isModalVisibleDelete: false,
      headers: [
        [this.$t('CRUD.TABLE.NAME'), this.$t('CRUD.TABLE.DESCRIPTION'), this.$t('TABLE.TITLE.ACTIONS')],
      ],
      pageActual: 0,
    };
  },

  computed: {
    tableReports() {
      return this.items?.map(dataItem => ({
        name: dataItem.name,
        description: dataItem.description,
      }));
    },

    itemsByPage() {
      const start = this.pageActual * 10;
      const end = start + 10;
      return this.items?.slice(start, end);
    },
  },

  methods: {
    openModal() {
      this.isModalVisible = true;
    },

    close() {
      this.isModalVisible = false;
    },

    closeModal() {
      this.isModalVisibleDelete = false;
    },

    editEntity(item) {
      this.dataItem = item;
      this.isModalVisible = true;
    },

    deleteItem(item) {
      this.dataItem = item;
      this.isModalVisibleDelete = true;
    },

    closeModalDelete() {
      this.isModalVisibleDelete = false;
    },

    async updateItem(payload) {
      if (payload.entity === 'Centers') {
        await this.$store.dispatch('adminModule/editCenter', payload);
        this.isModalVisible = false;
      } else if (payload.entity === 'Courses') {
        await this.$store.dispatch('adminModule/editCourse', payload);
        this.isModalVisible = false;
      }
    },

    async changeStateItem(payload) {
      if (payload.entity === 'Centers') {
        await this.$store.dispatch('adminModule/changeStateCenter', payload);
        this.isModalVisibleDelete = false;
      } else if (payload.entity === 'Courses') {
        await this.$store.dispatch('adminModule/changeStateCourse', payload);
        this.isModalVisibleDelete = false;
      }
    },

    setPage(payload) {
      this.pageActual = payload;
    },
  },

};
</script>

<style lang="scss" scoped>
.TabledataItem {
  background: #fff;
  padding: $spacing-xl;
  box-shadow: $shadow-elevation-1;

  .table:empty {
    @include empty-content(150px);
  }

  .table-actions {
    display: grid;
    justify-content: end;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    gap: $spacing-s;

    .icon {
      font-size: 16px;
    }

    .trash {
      color: $color-error;
    }
    .BaseButton {
      min-width: 30px;
    }
  }
}
</style>
