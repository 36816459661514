import { render, staticRenderFns } from "./FormInput.vue?vue&type=template&id=403d8d32&scoped=true&"
import script from "./FormInput.vue?vue&type=script&lang=js&"
export * from "./FormInput.vue?vue&type=script&lang=js&"
import style0 from "./FormInput.vue?vue&type=style&index=0&id=403d8d32&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "403d8d32",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/gestionetmultimedia/Documents/proyect_fronted/ortxadarfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('403d8d32')) {
      api.createRecord('403d8d32', component.options)
    } else {
      api.reload('403d8d32', component.options)
    }
    module.hot.accept("./FormInput.vue?vue&type=template&id=403d8d32&scoped=true&", function () {
      api.rerender('403d8d32', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/FormInput.vue"
export default component.exports