var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "PaneAdmin" },
    [
      _c("EmptySubHeader", { staticClass: "subheader" }),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("TransitionFade", [
            _c("div", { staticClass: "head" }, [
              _vm.user
                ? _c("h1", [
                    _vm._v(" " + _vm._s(_vm.$t("TITLE.STATS.ADMIN")) + " ")
                  ])
                : _vm._e(),
              _c("div")
            ])
          ]),
          _c("TransitionFade", [
            _c(
              "div",
              { staticClass: "users" },
              _vm._l(_vm.stats, function(stat, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "card",
                    style: { border: "solid  1px " + stat.color }
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "title",
                        style: { "border-bottom": "solid  1px " + stat.color }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t(stat.title)) + " ")]
                    ),
                    _c("div", { staticClass: "content-card" }, [
                      _c(
                        "p",
                        {
                          staticClass: "icono",
                          style: { background: stat.color }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { size: "lg", icon: stat.icon }
                          })
                        ],
                        1
                      ),
                      _c("p", { staticClass: "value" }, [
                        _vm._v(" " + _vm._s(stat.total) + " ")
                      ])
                    ])
                  ]
                )
              }),
              0
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }