<template>
  <div class="QuestionFile">
    <BaseInputFile
      tag="image"
      :accept="accept"
      @change.native="loadImage($event)"
    />
    <div
      class="preview"
      :style="{backgroundImage:'url('+currentImage+')'}"
    />
  </div>
</template>

<script>
export default {
  props: {
    accept: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      file: null,
      preview: null,
    };
  },
  computed: {
    currentImage() {
      return this.file ? this.preview : 'https://picsum.photos/700/400?random';
    },
  },

  methods: {
    loadImage(event) {
      const input = event.target;

      if (input.files && input.files[0]) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.preview = e.target.result;
        };

        this.file = input.files[0];

        reader.readAsDataURL(input.files[0]);
      }
    },
  },

};
</script>

<style lang="scss" scoped>
  .QuestionFile{
    .preview{
      position: relative;
      --local-image-size: 19rem;
      padding: var(--spacing-m);
      height: var(--local-image-size);
      width: var(--local-image-size);
      border-radius: 5px;
      background-color: var(--color-neutral-mid-light);
      margin-bottom: var(--spacing-xs);
      background-size: cover;
    }
  }
</style>
