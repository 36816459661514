var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Edititem" }, [
    _c(
      "form",
      {
        on: {
          input: function($event) {
            return _vm.validation($event)
          },
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.submit.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "form-body" },
          [
            _c(
              "FormComponent",
              {
                attrs: { tag: "type", orientation: "row", errors: _vm.errors }
              },
              [
                _c(
                  "select",
                  {
                    attrs: { id: "type", name: "type", required: "" },
                    on: {
                      change: function($event) {
                        return _vm.onChange($event)
                      }
                    }
                  },
                  _vm._l(4, function(register) {
                    return _c(
                      "option",
                      { key: register, domProps: { value: register } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("QUESTION.REGISTER.CHOICE." + register)
                            ) +
                            " "
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            ),
            _c(
              "FormComponent",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.itemCopy.typeRegister === 1 ||
                      _vm.itemCopy.typeRegister === "1",
                    expression:
                      "itemCopy.typeRegister === 1 || itemCopy.typeRegister === '1'"
                  }
                ],
                attrs: { tag: "center", orientation: "row", errors: _vm.errors }
              },
              [
                _c(
                  "select",
                  {
                    attrs: { id: "center", name: "center", required: "" },
                    on: {
                      change: function($event) {
                        return _vm.onChangeCenter($event)
                      }
                    }
                  },
                  _vm._l(_vm.centers, function(cent) {
                    return _c(
                      "option",
                      { key: cent.id, domProps: { value: cent.id } },
                      [_vm._v(" " + _vm._s(cent.nameEdit) + " ")]
                    )
                  }),
                  0
                )
              ]
            ),
            _c(
              "FormComponent",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.itemCopy.typeRegister === 1 ||
                      _vm.itemCopy.typeRegister === "1",
                    expression:
                      "itemCopy.typeRegister === 1 || itemCopy.typeRegister === '1'"
                  }
                ],
                attrs: { tag: "course", orientation: "row", errors: _vm.errors }
              },
              [
                _c(
                  "select",
                  { attrs: { id: "course", name: "course", required: true } },
                  _vm._l(_vm.courses, function(cours) {
                    return _c(
                      "option",
                      { key: cours.id, domProps: { value: cours.id } },
                      [_vm._v(" " + _vm._s(cours.name) + " ")]
                    )
                  }),
                  0
                )
              ]
            ),
            _c(
              "FormComponent",
              {
                staticClass: "form-component",
                attrs: {
                  tag: "firstName",
                  orientation: "row",
                  errors: _vm.errors
                }
              },
              [
                _c("LoginInput", {
                  attrs: {
                    type: "text",
                    tag: "firstName",
                    placeholder: _vm.$t("PROFILE.NAME.LABEL"),
                    title: _vm.$t("FORM.ERROR.EMPTYFIELD"),
                    required: true
                  },
                  model: {
                    value: _vm.itemCopy.firstName,
                    callback: function($$v) {
                      _vm.$set(_vm.itemCopy, "firstName", $$v)
                    },
                    expression: "itemCopy.firstName"
                  }
                })
              ],
              1
            ),
            _c(
              "FormComponent",
              {
                staticClass: "form-component",
                attrs: {
                  tag: "lastName",
                  orientation: "row",
                  errors: _vm.errors
                }
              },
              [
                _c("LoginInput", {
                  attrs: {
                    type: "text",
                    tag: "lastName",
                    placeholder: _vm.$t("PROFILE.NAME.LABEL"),
                    title: _vm.$t("FORM.ERROR.EMPTYFIELD"),
                    required: true
                  },
                  model: {
                    value: _vm.itemCopy.lastName,
                    callback: function($$v) {
                      _vm.$set(_vm.itemCopy, "lastName", $$v)
                    },
                    expression: "itemCopy.lastName"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-footer" },
          [
            _c(
              "BaseButton",
              {
                staticClass: "button",
                attrs: { size: "s" },
                on: { click: _vm.submit }
              },
              [_vm._v(" " + _vm._s(_vm.$t("BUTTON.SAVE")) + " ")]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }