<template>
  <div class="addCenter">
    <form
      @input="validation($event)"
      @keyup.enter="submit"
    >
      <div class="form-body">
        <FormComponent
          v-if="entity === 'centers'"
          tag="province"
          orientation="row"
          :errors="errors"
        >
          <select
            id="province"
            name="province"
            required
            @change="onChange($event)"
          >
            <option
              v-for="item in 3"
              :key="item"
              :value="item"
            >
              {{ $t(`QUESTION.NA1.CHOICE.${item}`) }}
            </option>
          </select>
        </FormComponent>


        <FormComponent
          v-if="entity === 'centers'"
          tag="code"
          class="code"
          orientation="row"
          :errors="errors"
        >
          <LoginInput
            type="text"
            tag="code"
            :placeholder="$t('CODE.CENTER')"
            :title="$t('FORM.ERROR.EMPTYFIELD')"
          />
        </FormComponent>

        <FormInput
          v-for="(field, i) in fields"
          :key="i"
          v-model="field.value"
          v-bind="field"
        >
          <template #error>
            <ValidationError
              :errors="errors"
              :name="field.name"
            />
          </template>
        </FormInput>
      </div>

      <div class="form-footer">
        <BaseButton
          size="s"
          class="button"
          @click="submit"
        >
          {{ $t('BUTTON.SAVE') }}
        </BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import FormInput from '@/components/common/FormInput';
import FormComponent from '@/components/common/FormComponent';
import ValidationError from '@/components/common/ValidationError';
import ValidationMixin from '@/mixins/ValidationMixin';
import LoginInput from '@/components/Login/LoginInput';


export default {
  components: {
    FormInput,
    FormComponent,
    LoginInput,
    ValidationError,
  },

  mixins: [ValidationMixin],

  props: {
    entity: {
      type: String,
      default: 'Course',
    },
  },

  data() {
    return {
      province: 1,
      fields: [
        {
          type: 'text',
          name: 'name',
          placeholder: this.$t('PLACEHOLDER.ADDCENTER.NAME'),
          /*  label: 'ADDCENTER.NAME.LABEL', */
          error: this.$t('ADDCENTER.ERROR.NAME'),
          required: true,
          value: undefined,
        },
        {
          type: 'text',
          name: 'description',
          placeholder: this.$t('PLACEHOLDER.ADDCENTER.DESCRIPTION'),
          /*  label: 'ADDCENTER.DESCRIPTION.LABEL', */
          error: this.$t('ADDCENTER.ERROR.DESCRIPTION'),

          required: false,
          value: undefined,
        },

      ],
    };
  },


  methods: {
    async submit() {
      const currentForm = document.forms[0];
      this.checkAllFields(currentForm);
      if (this.areErrors()) {
        this.$toast.error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
      } else {
        const formData = new FormData(currentForm);
        const code = formData.get('form-code');

        const data = this.fields.reduce((acc, field) => (Object.assign(acc, { [field.name]: formData.get(field.name) })), {});

        const extra = {
          code,
          // eslint-disable-next-line radix
          province: parseInt(this.province),
        };
        const result = {
          ...data,
          ...extra,

        };

        this.send(result);
      }
    },

    getField(name) {
      return this.fields.find(field => field.name === name)?.value;
    },

    onChange(event) {
      this.province = event.target.value;
    },

    send(data) {
      this.$emit('add-entity', data);
      // eslint-disable-next-line no-unused-expressions
    /*   this.fields = [{
        type: 'text',
        name: 'name',
        placeholder: this.$t('PLACEHOLDER.ADDCENTER.NAME'),
        label: 'ADDCENTER.NAME.LABEL',
        error: this.$t('ADDCENTER.ERROR.NAME'),
        required: true,
        value: undefined,
      },
      {
        type: 'text',
        name: 'description',
        placeholder: this.$t('PLACEHOLDER.ADDCENTER.DESCRIPTION'),
        label: 'ADDCENTER.DESCRIPTION.LABEL',
        error: this.$t('ADDCENTER.ERROR.DESCRIPTION'),
        pattern: '.{8,}',
        required: true,
        value: undefined,
      }]; */
    },

  },

};
</script>

<style lang="scss" scoped>
  .addCenter{
    background: #fff;
    padding: $spacing-m;
    display: flex;
    flex-direction: column;

    .form-body{
      margin: 1rem;
      input{
        width: 95% !important;
      }

        select {
      width: 95%;
     // margin-left: 1.5rem;
      height: 3rem;
      border: 0;
      border-bottom: solid 1px;
      position: relative;
      margin-top: -2rem;
      margin-bottom: 1rem;
      font-size: $font-size-m;
      background: white;

      &::placeholder {
        color: var($color-neutral-light);
      }
    }

    .select-error {
      border-bottom: solid 1px red;
    }

    .title {
      font-size: $font-size-2xl;
      letter-spacing: 1px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: $spacing-l;
      text-align: center;
    }

    .code{
      width: 95%;
    }
    }

    .form-footer{
     display: grid;
     grid-template-columns: repeat(2, 1fr);

     .button{
       grid-column: 2/3;
     }
    }
  }
</style>
