import { render, staticRenderFns } from "./ModalDelete.vue?vue&type=template&id=5a58cef8&scoped=true&"
import script from "./ModalDelete.vue?vue&type=script&lang=js&"
export * from "./ModalDelete.vue?vue&type=script&lang=js&"
import style0 from "./ModalDelete.vue?vue&type=style&index=0&id=5a58cef8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a58cef8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/gestionetmultimedia/Documents/proyect_fronted/ortxadarfront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5a58cef8')) {
      api.createRecord('5a58cef8', component.options)
    } else {
      api.reload('5a58cef8', component.options)
    }
    module.hot.accept("./ModalDelete.vue?vue&type=template&id=5a58cef8&scoped=true&", function () {
      api.rerender('5a58cef8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/ModalDelete.vue"
export default component.exports