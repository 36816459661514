<template>
  <div class="Edititem">
    <!-- <div>
      hola  {{ itemCopy.typeRegister }} {{ type }} <br>
      {{ itemCopy }}
    </div> -->
    <form
      @input="validation($event)"
      @keyup.enter="submit"
    >
      <div class="form-body">
        <FormComponent
          tag="type"
          orientation="row"
          :errors="errors"
        >
          <select
            id="type"
            name="type"
            required
            @change="onChange($event)"
          >
            <!-- <option>
            {{ $t('SELECT.PLACEHOLDER') }}
          </option> -->
            <option
              v-for="register in 4"
              :key="register"
              :value="register"
            >
              {{ $t(`QUESTION.REGISTER.CHOICE.${register}`) }}
            </option>
          </select>
        </FormComponent>

        <FormComponent
          v-show="itemCopy.typeRegister === 1 || itemCopy.typeRegister === '1'"
          tag="center"
          orientation="row"
          :errors="errors"
        >
          <select
            id="center"
            name="center"
            required
            @change="onChangeCenter($event)"
          >
            <option
              v-for="cent in centers"
              :key="cent.id"
              :value="cent.id"
            >
              {{ cent.nameEdit }}
            </option>
          </select>
        </FormComponent>

        <FormComponent
          v-show="itemCopy.typeRegister === 1 || itemCopy.typeRegister === '1'"
          tag="course"
          orientation="row"
          :errors="errors"
        >
          <select
            id="course"
            name="course"
            :required="true"
          >
            <!--  <option
            selected
            value=""
          >
            {{ $t('SELECT.PLACEHOLDER.COURSE') }}
          </option> -->
            <option
              v-for="cours in courses"
              :key="cours.id"
              :value="cours.id"
            >
              {{ cours.name }}
            </option>
          </select>
        </FormComponent>


        <FormComponent
          class="form-component"
          tag="firstName"
          orientation="row"
          :errors="errors"
        >
          <LoginInput
            v-model="itemCopy.firstName"
            type="text"
            tag="firstName"
            :placeholder="$t('PROFILE.NAME.LABEL') "
            :title="$t('FORM.ERROR.EMPTYFIELD')"
            :required="true"
          />
        </FormComponent>

        <FormComponent
          class="form-component"
          tag="lastName"
          orientation="row"
          :errors="errors"
        >
          <LoginInput
            v-model="itemCopy.lastName"
            type="text"
            tag="lastName"
            :placeholder="$t('PROFILE.NAME.LABEL') "
            :title="$t('FORM.ERROR.EMPTYFIELD')"
            :required="true"
          />
        </FormComponent>
      </div>

      <div class="form-footer">
        <BaseButton
          size="s"
          class="button"
          @click="submit"
        >
          {{ $t('BUTTON.SAVE') }}
        </BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import { get } from 'vuex-pathify';

import FormComponent from '@/components/common/FormComponent';
import LoginInput from '@/components/Login/LoginInput';
import ValidationMixin from '@/mixins/ValidationMixin';

export default {
  components: {
    LoginInput,
    FormComponent,
  },

  mixins: [ValidationMixin],

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      itemCopy: {
        id: undefined,
        firstName: undefined,
        lastName: undefined,
        center: undefined,
        course: undefined,
        typeRegister: undefined,
      },
      type: undefined,
      center: undefined,
      course: undefined,
    };
  },

  computed: {
    ...get('adminModule', ['getCenters', 'getCourses']),

    centers() {
      return this.getCenters();
    },

    courses() {
      return this.getCourses();
    },
  },

  watch: {
    item: {
      immediate: true,
      handler(newVal) {
        if (!newVal) return;
        this.itemCopy = { ...newVal };
        this.type = this.itemCopy.typeRegister;
        this.center = this.itemCopy.center;
        this.course = this.itemCopy.course;

        const type = document.getElementById('type');
        type.value = this.type;

        const center = document.getElementById('center');
        center.value = this.center;

        const course = document.getElementById('course');
        course.value = this.course;
      },
    },

    type() {
      this.type = this.itemCopy.typeRegister;
    },
  },

  mounted() {
    console.log('Creando nuevamente');
    this.type = this.item.typeRegister;
    this.center = this.item.center;
    this.course = this.item.course;
    console.log('item', this.type);
  },

  methods: {
    submit() {
      const currentForm = document.forms[0];
      const formData = new FormData(currentForm);
      const firstName = formData.get('firstName');
      const lastName = formData.get('lastName');
      this.checkAllFields(currentForm);


      console.log(lastName);
      if (this.areErrors()) {
        this.$toast.error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
      } else {
        const data = {
          id: this.itemCopy.id, firstName, lastName,
        };
        this.send(data);
      }
    },

    getField(name) {
      return this.fields.find(field => field.name === name)?.value;
    },

    /* onChange(event) {
      this.itemCopy.province = event.target.value;
    }, */


    async send(data) {
      //  console.log('data', data);
      const dataObject = {
        idUser: data.id,
        firstName: this.itemCopy.firstName,
        lastName: this.itemCopy.lastName,
        type: this.itemCopy.typeRegister,
        center: this.center,
        course: this.course,
        state: 1,

      };

      console.log('data object', dataObject);
      this.$emit('update-item', dataObject);
    },

    onChange(event) {
      this.type = event.target.value;
      this.itemCopy.typeRegister = event.target.value;
      console.log('Cambiando ', this.itemCopy.typeRegister);
    },

    onChangeCenter(event) {
      this.center = event.target.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.Edititem {
  background: #fff;
  padding: $spacing-m;
  display: flex;
  flex-direction: column;

  .form-body {
    margin: 1rem;

    .label {
      font-weight: bold;
      margin: 1rem 0 1.5rem;
    }

    select {
      width: 100%;
      height: 3rem;
      border: 0;
      border-bottom: solid 1px;
      position: relative;
      margin-top: 0.4rem;
      margin-bottom: 1rem;
      font-size: $font-size-m;
      background: white;

      &::placeholder {
        color: var($color-neutral-light);
      }
    }
    .code {
      width: 100%;
    }

    .form-component{
      margin-top: 1rem;
    }
  }

  .form-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .button {
      grid-column: 2/3;
    }
  }
}
</style>
