<template>
  <div class="Edititem">
    <form
      @input="validation($event)"
      @keyup.enter="submit"
    >
      <div class="form-body">
        <FormComponent
          v-if="entity === 'Centers'"
          tag="province"
          orientation="row"
          :errors="errors"
        >
          <select
            id="province"
            v-model="itemCopy.province"
            name="province"
            required
          >
            <option
              v-for="prov in 3"
              :key="prov"
              :value="prov"
            >
              {{ $t(`QUESTION.NA1.CHOICE.${prov}`) }}
            </option>
          </select>
        </FormComponent>

        <FormComponent
          v-if="entity === 'Centers'"
          tag="code"
          class="code"
          orientation="row"
          :errors="errors"
        >
          <LoginInput
            v-model="itemCopy.code"
            type="text"
            tag="code"
            :placeholder="$t('CODE.CENTER')"
            :title="$t('FORM.ERROR.EMPTYFIELD')"
          />
        </FormComponent>

        <FormComponent
          class="form-component"
          tag="name"
          orientation="row"
          :errors="errors"
        >
          <LoginInput
            v-model="itemCopy.nameEdit"
            type="text"
            tag="name"
            :placeholder="$t('PROFILE.NAME.LABEL') "
            :title="$t('FORM.ERROR.EMPTYFIELD')"
            :required="true"
          />
        </FormComponent>

        <FormComponent
          class="form-component"
          tag="description"
          orientation="row"
          :errors="errors"
        >
          <LoginInput
            v-model="itemCopy.description"
            type="text"
            tag="description"
            :placeholder="$t('PROFILE.NAME.LABEL') "
            :title="$t('FORM.ERROR.EMPTYFIELD')"
          />
        </FormComponent>
      </div>

      <div class="form-footer">
        <BaseButton
          size="s"
          class="button"
          @click="submit"
        >
          {{ $t('BUTTON.SAVE') }}
        </BaseButton>
      </div>
    </form>
  </div>
</template>

<script>
import FormComponent from '@/components/common/FormComponent';
import LoginInput from '@/components/Login/LoginInput';
import ValidationMixin from '@/mixins/ValidationMixin';

export default {
  components: {
    LoginInput,
    FormComponent,
  },

  mixins: [ValidationMixin],

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },

    entity: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      itemCopy: {
        id: undefined,
        nameEdit: undefined,
        description: undefined,
        code: undefined,
        province: undefined,
      },
    };
  },

  watch: {
    item: {
      immediate: true,
      handler(newVal) {
        if (!newVal) return;
        this.itemCopy = { ...newVal };
      },
    },
  },


  methods: {
    submit() {
      const currentForm = document.forms[0];
      const formData = new FormData(currentForm);
      const name = formData.get('form-name');
      const description = formData.get('form-description');
      const code = formData.get('form-code');
      const province = formData.get('province');

      this.checkAllFields(currentForm);

      if (this.areErrors()) {
        this.$toast.error(this.$t('FORM.MESSAGE.ERROR.CHECKFIELDS'));
      } else {
        const data = {
          id: this.itemCopy.id, name, description, code, province,
        };
        this.send(data);
      }
    },
    getField(name) {
      return this.fields.find(field => field.name === name)?.value;
    },

    /* onChange(event) {
      this.itemCopy.province = event.target.value;
    }, */


    async send(data) {
      //  console.log('data', data);
      const dataObject = {
        id: data.id,
        name: data.name,
        description: data.description,
        code: data.code,
        province: data.province,
        entity: this.entity,
      };
      this.$emit('update-item', dataObject);
    },

  },


};
</script>

<style lang="scss" scoped>
.Edititem {
  background: #fff;
  padding: $spacing-m;
  display: flex;
  flex-direction: column;

  .form-body {
    margin: 1rem;

    .label {
      font-weight: bold;
      margin: 1rem 0 1.5rem;
    }

    select {
      width: 100%;
      height: 3rem;
      border: 0;
      border-bottom: solid 1px;
      position: relative;
      margin-top: -2rem;
      margin-bottom: 1rem;
      font-size: $font-size-m;
      background: white;

      &::placeholder {
        color: var($color-neutral-light);
      }
    }
    .code {
      width: 100%;
    }

    .form-component{
      margin-top: 1rem;
    }
  }

  .form-footer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    .button {
      grid-column: 2/3;
    }
  }
}
</style>
