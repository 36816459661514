<template>
  <div class="GoogleMapView">
    <div class="header">
      <TransitionSlide>
        <div class="text">
          <h1>Question de tipo File</h1>
        </div>
      </TransitionSlide>
    </div>

    <TransitionSlide side="bottom">
      <div class="content">
        <QuestionFile />
      </div>
    </TransitionSlide>
  </div>
</template>

<script>

import TransitionSlide from '@/transitions/TransitionSlide';
import QuestionFile from '@/components/map/QuestionFile';


export default {
  components: {
    TransitionSlide,
    QuestionFile,

  },

  data() {
    return {
      coordinates: {
        lat: 43.3530573,
        lng: -2.8405813,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.GoogleMapView {
  .header {
    padding-top: $theHeaderSpacing;
    background-image: url('~@/assets/images/f_home.jpg');
    background-size: cover;
    height: 200px;
    .text {
      margin: 3rem auto 3rem;
      max-width: 1200px;
      padding: 0 2rem 2rem;
      h1 {
        color: white;
        text-transform: uppercase;
        font-size: $font-size-3xl;
        font-weight: bold;
      }
    }
  }

  .content {
    max-width: 1200px;
    margin: 1rem auto;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
  }
}
</style>
