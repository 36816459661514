var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "TabledataItem" }, [
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "TransitionFade",
          [
            _vm.items
              ? _c("BaseTable", {
                  attrs: {
                    headers: _vm.headers,
                    body: _vm.tableReports,
                    pagination: { current: _vm.pageActual, size: 10 }
                  },
                  on: { "set-page": _vm.setPage },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.itemsByPage, function(item, i) {
                        return {
                          key: "actions-" + i,
                          fn: function() {
                            return [
                              _c(
                                "div",
                                { key: i, staticClass: "table-actions" },
                                [
                                  _c(
                                    "BaseButton",
                                    {
                                      attrs: {
                                        variation: "tertiary",
                                        size: "s"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteItem(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "icon trash",
                                        attrs: {
                                          size: "xs",
                                          icon: ["fas", "trash"]
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "BaseButton",
                                    {
                                      attrs: {
                                        variation: "secondary",
                                        size: "s"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.editEntity(item)
                                        }
                                      }
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "icon",
                                        attrs: {
                                          size: "xs",
                                          icon: ["fas", "edit"]
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      })
                    ],
                    null,
                    true
                  )
                })
              : _vm._e()
          ],
          1
        )
      ],
      1
    ),
    _vm.dataItem !== undefined
      ? _c(
          "div",
          [
            _c(
              "BaseModal",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isModalVisible,
                    expression: "isModalVisible"
                  }
                ],
                attrs: { "header-visible": true, size: "s" },
                on: { close: _vm.close }
              },
              [
                _c("EditEntity", {
                  attrs: { item: _vm.dataItem, entity: _vm.entity },
                  on: {
                    "update-item": function($event) {
                      return _vm.updateItem($event)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e(),
    _vm.dataItem !== undefined
      ? _c(
          "div",
          [
            _c(
              "BaseModal",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isModalVisibleDelete,
                    expression: "isModalVisibleDelete"
                  }
                ],
                attrs: { "header-visible": true, size: "s" },
                on: { close: _vm.closeModalDelete }
              },
              [
                _c("DeleteItem", {
                  attrs: { item: _vm.dataItem, entity: _vm.entity },
                  on: {
                    "close-modal-delete": function($event) {
                      return _vm.closeModal($event)
                    },
                    "change-state": function($event) {
                      return _vm.changeStateItem($event)
                    }
                  }
                })
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }