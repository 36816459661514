<template>
  <div class="FormInput">
    <label
      v-if="$attrs.label"
      :for="$attrs.id"
    >
      {{ $t($attrs.label) }}
    </label>
    <div
      class="input"
    >
      <div
        v-if="icon"
        class="icon"
      >
        <font-awesome-icon :icon="icon" />
      </div>

      <input
        v-model="innerValue"
        v-bind="inputAttributes"
      >

      <span
        v-if="units && $attrs.type !== 'range'"
        class="units"
      >{{ units }}</span>
    </div>


    <slot name="error" />
  </div>
</template>

<script>

export default {
  inheritAttrs: false,

  props: {
    value: {
      type: null,
      default: undefined,
    },
  },


  computed: {
    innerValue: {
      get() {
        return this.value;
      },

      set(newValue) {
        let value = newValue;
        if (this.$attrs.type === 'number') {
          value = (newValue === '') ? undefined : parseInt(newValue, 10);
        }

        this.$emit('input', value);
      },
    },

    icon() {
      return this.$attrs?.icon;
    },

    units() {
      return this.$attrs?.units;
    },

    inputAttributes() {
      return Object.assign({}, this.$attrs, { label: undefined, type: this.type, icon: undefined });
    },

    type() {
      return (!this.$attrs.type || this.$attrs.disabled) ? 'text' : this.$attrs.type;
    },
  },


};
</script>


<style lang="scss" scoped>
.FormInput {
  display: flex;
  flex-direction: column;

  label {
    margin: $spacing-xs $spacing-2xs;
    font-weight: 500;
  }

  .input {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: $spacing-2xs;


    .icon {
      z-index: 3;
      color: $color-neutral-dark !important;
      position: absolute;
      top: 0;
      left: $spacing-l;
      bottom: 1.5rem;
      display: grid;
      place-content: center;

      & + input {
        padding-left: 3.5rem;
      }
    }

    .value{
      background: $color-success;
      padding: $spacing-xs;
      color:#fff;
      font-weight: 500;
      border-radius: 10px;
      width: 5rem;
      text-align: center;
      box-shadow: $shadow-elevation-1;
    }

    .units{
      font-weight: 600;
      background: $color-primary-dark;
      color:#fff;
      padding: $spacing-xs;
      width: 4rem;
      text-align: center;
      box-shadow: $shadow-elevation-1;
      border-radius: 10px;
    }
  }

  input {
    font-size: $font-size-m;
    padding: $spacing-s 0;
    border: none;
    border-bottom: solid 1px $color-neutral-darkest;
    margin-bottom: $spacing-m;
   //border-radius: 50px;
    max-width: 500px;
    width: 100%;
    //background-color: $color-neutral-light;

    &:focus {
      border-color: $color-primary;
      outline: none;
    }

    &:-moz-focusring {
      text-shadow: 0 0 0 transparent;
    }

    &.invalid {
      border: solid 1px #{$color-error};
    }
  }

}
</style>
