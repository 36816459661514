<template>
  <div class="TableCenter">
    <div class="table">
      <TransitionFade>
        <BaseTable
          v-if="items"
          :headers="headers"
          :body="tableReports"
          :pagination="{current: pageActual, size: 10}"
          @set-page="setPage"
        >
          <template
            v-for="(user, i) in showUsersByCurrentPage"
            v-slot:[`actions-${i}`]
          >
            <div
              :key="i"
              class="table-actions"
            >
              <BaseButton
                variation="tertiary"
                size="s"
                @click="deleteItem(user)"
              >
                <font-awesome-icon
                  class="icon trash"
                  size="xs"
                  :icon="['fas', 'trash']"
                />
              </BaseButton>

              <BaseButton
                variation="secondary"
                size="s"
                @click="updateItem(user)"
              >
                <font-awesome-icon
                  class="icon"
                  size="xs"
                  :icon="['fas', 'edit']"
                />
              </BaseButton>

              <BaseButton
                size="s"
                @click="goToReportUser(user.id)"
              >
                <span> {{ $t('BUTTON.SEE') }}</span>
                <font-awesome-icon
                  size="xs"
                  :icon="['fas', 'eye']"
                />
              </BaseButton>

              <BaseModal
                v-show="isModalVisibleDelete"
                :header-visible="true"
                size="s"
                @close="closeModalDelete"
              >
                <DeleteItem
                  :item="dataItem"
                  :entity="entity"
                  @close-modal-delete="closeModal($event)"
                  @change-state="changeStateUsers($event)"
                />
              </BaseModal>
            </div>
          </template>
        </BaseTable>
      </TransitionFade>
    </div>

    <div v-if="dataItem!== undefined">
      <BaseModal
        v-show="isModalVisibleEdit"
        :header-visible="true"
        size="s"
        @close="closeModaUpdate"
      >
        <EditUser
          :item="dataItem"
          @update-item="updateUser($event)"
        />
      </BaseModal>
    </div>
  </div>
</template>

<script>
import TransitionFade from '@/transitions/TransitionFade';
import DeleteItem from '@/components/common/ModalDelete';
import EditUser from '@/components/panel-admin/EditUser';


export default {
  components: {
    TransitionFade,
    DeleteItem,
    EditUser,
  },

  props: {
    items: {
      type: Array,
      default: undefined,
    },
  },

  data() {
    return {
      isModalVisible: false,
      headers: [
        [this.$t('TABLE.TITLE.AVATARS'), this.$t('TABLE.TITLE.NAME.USER'), this.$t('TABLE.TITLE.LASTNAME.USER'), this.$t('TABLE.TITLE.ACTIONS')],
      ],
      isModalVisibleDelete: false,
      dataItem: undefined,
      entity: 'users',
      isModalVisibleEdit: false,
      pageActual: 0,
    };
  },

  computed: {
    tableReports() {
      return this.items?.map(user => ({
        avatar: user.avatar,
        firtName: user.firstName,
        lastName: user.lastName,
      }));
    },

    showUsersByCurrentPage() {
      const start = this.pageActual * 10;
      const end = start + 10;
      return this.items?.slice(start, end);
    },
  },

  methods: {
    goToReportUser(userId) {
      return this.$router.push({ name: 'ReportByUser', params: { userId } });
    },

    deleteItem(item) {
      this.dataItem = item;
      this.isModalVisibleDelete = true;
    },

    closeModalDelete() {
      this.isModalVisibleDelete = false;
    },

    closeModal() {
      this.isModalVisibleDelete = false;
    },

    async changeStateUsers(payload) {
      const data = {
        idUser: payload?.id,
        state: 0,
      };

      await this.$store.dispatch('adminModule/changeStateUser', data);
    },

    updateItem(item) {
      this.dataItem = item;
      console.log('data item', item);
      this.isModalVisibleEdit = true;
    },

    closeModaUpdate() {
      console.log('Cerrando ');
      /*  this.$destroy();
      this.$el.parentNode.removeChild(this.$el); */
      this.isModalVisibleEdit = false;
      // window.location.reload();
    },

    async updateUser(payload) {
      console.log('Recibiendo data usuario', payload);
      await this.$store.dispatch('adminModule/updateUser', payload);
    },

    setPage(payload) {
      this.pageActual = payload;
    },
  },

};
</script>

<style lang="scss" scoped>
.TableCenter {
  background: #fff;
  padding: $spacing-xl;
  box-shadow: $shadow-elevation-1;

  .table:empty {
    @include empty-content(150px);
  }

  .table-actions {
    display: grid;
    justify-content: end;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    gap: $spacing-s;

    .icon {
      font-size: 16px;
    }

    .trash {
      color: $color-error;
    }

    .BaseButton {
      min-width: 30px;
    }
  }
}
</style>
